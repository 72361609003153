import React, { PropsWithChildren, useEffect, useState } from "react";
import { ITokenModel, TokenContext } from "../contexts/tokenContext";
import { UserService } from "../api";
import { Result, Spin } from "antd";
import { useWebApp } from "../tg-api";
import { OpenAPI } from "../api/core/OpenAPI";
import { MobileSelect } from "../../feature";

export interface IResponseContainer<T> {
  isSuccess: boolean;
  errorText?: string;
  data?: T;
}

export const TokenProvider = ({ children }: PropsWithChildren) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const WebApp = useWebApp();
  const [tokenResponse, setTokenResponse] =
    useState<IResponseContainer<ITokenModel>>();
  useEffect(() => {
    const jString = JSON.stringify(WebApp?.initDataUnsafe);
    const jObject = JSON.parse(jString);
    UserService.postApiUserTokenTelegram({
      requestBody: jObject,
    }).then((result: IResponseContainer<ITokenModel>) => {
      OpenAPI.TOKEN = result?.data?.token;
      setTokenResponse(result);
      setLoaded(true);
    });
  }, []);

  return (
    <TokenContext.Provider value={tokenResponse?.data ?? null}>
      {!loaded && (
        <div className="flex justify-center content-center h-full w-full">
          <Spin tip="Loading..." />
        </div>
      )}
      {loaded && tokenResponse?.isSuccess && children}
      {loaded && tokenResponse?.isSuccess !== true && (
        <Result
          status="403"
          title="403"
          subTitle={
            tokenResponse?.errorText ??
            "К сожалению, у вас нет прав доступа к этой странице."
          }
        />
      )}
      {/* <MobileSelect options={[{ value: "test", label: "test" }]} size="large" /> */}
      {/* {loaded && children} */}
      {/* <div>{JSON.stringify(tokenResponse)}</div> */}
    </TokenContext.Provider>
  );
};
