import { Button, Flex, Skeleton, Space, Spin, Typography, theme } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { MainButton, useThemeParams } from "shared/tg-api";
import { PropsWithChildren } from "react";

export type BType = "primary" | "danger";
interface IBtnProps {
  type?: BType;
  title: string;
  disabled?: boolean;
  onClick: () => void;
}
interface ScreenProps extends PropsWithChildren {
  loading: boolean;
  btn?: IBtnProps;
  title: string;
  extra?: {
    onClick: () => void;
  };
}

export const Screen = ({
  loading,
  btn,
  title,
  extra,
  children
}: ScreenProps) => {
  const { token } = theme.useToken();

  
  const Btn = ({ type, title, disabled, onClick }: IBtnProps) => {
    const { token } = theme.useToken();
    const [colorScheme] = useThemeParams();

    const isDark = colorScheme === "dark";
    const disabledColor = isDark ? "#343e4b" : "#e8e8e8";
    const disabledTextColor = isDark ? "#676e78" : "#aeaeae";
    const btnTheme = {
      color: "#a73746",
      disabledColor: disabledColor,
      textColor: token.colorText,
      disabledTextColor: disabledTextColor,
    };

    return (
      <>
        <MainButton
          disabled={loading || disabled}
          theme={type === "danger" ? btnTheme : undefined}
          onClick={onClick}
        >
          {title}
        </MainButton>
      </>
    );
  };

  return (
    <Spin spinning={loading}>
    <Flex vertical className="h-full grid grid-cols-1 gap-1 content-between">
      <Flex vertical>
        <div className="flex justify-between">
          <Typography.Title level={5} style={{ marginTop: 0 }}>
            {loading ? <Skeleton.Input /> : title}
          </Typography.Title>
          {extra && (
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => extra.onClick && extra.onClick()}
            />
          )}
        </div>
        {children}
      </Flex>
      <div>{btn && <Btn {...btn} />}</div>
    </Flex>
    </Spin>
  );
};
