import React, { PropsWithChildren } from "react";
import { useThemeParams } from "shared";
import { ConfigProvider, theme } from "antd";

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [colorScheme, themeParams] = useThemeParams();

  const validateMessages = {
    required: "Пожалуйста, введите '${label}'",
  };

  return (
    <ConfigProvider
      theme={
        themeParams.text_color
          ? {
              algorithm:
                colorScheme === "dark"
                  ? theme.darkAlgorithm
                  : theme.defaultAlgorithm,
              token: {
                colorText: themeParams.text_color,
                colorPrimary: themeParams.button_color,
                colorBgBase: themeParams.bg_color,
              },
            }
          : undefined
      }
      form={{ validateMessages }}
    >
      {children}
    </ConfigProvider>
  );
};
