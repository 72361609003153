import { createContext, useContext } from "react";

export interface ITokenModel {
  token: string;
  refreshToken: string;
  expires: string;
  expiresRefresh: string;
  role: string;
  userId: number;
}

export const TokenContext = createContext<ITokenModel | null>(null);

export const useTokenContext = (): ITokenModel => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error("TokenContext must be used within a TokenProvider");
  }
  return context;
};
