import React, { ReactNode } from "react";
import { Button, Card, Flex, Input, List, Skeleton, Typography } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

export interface ListDataModel {
  key: number | string;
  title: ReactNode;
  description: ReactNode;
  style?: React.CSSProperties;
}

export interface ListProps {
  data: ListDataModel[];
  loading: boolean;
  title?: ReactNode;
  onClick?: (value: ListDataModel) => void;
  onAddClick?: () => void;
}

const defaultData = Array.from({ length: 3 }).map((_, i) => ({
  key: i,
  title: "",
  description: "",
}));

export const Lists = ({
  title,
  data,
  loading,
  onClick,
  onAddClick,
}: ListProps) => {
  const Header = () => {
    return (
      <Flex justify="space-between">
        <Typography.Title level={3} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
        {onAddClick && (
          <Button
            type="primary"
            shape="circle"
            icon={<PlusCircleOutlined />}
            onClick={() => onAddClick && onAddClick()}
          />
        )}
      </Flex>
    );
  };

  return (
    <List
      header={(title || onAddClick) && <Header />}
      split={false}
      pagination={{
        onChange: (page) => {
          console.log(page);
        },
        align: "center",
        pageSize: 7,
        hideOnSinglePage: true,
      }}
      dataSource={loading ? defaultData : data}
      renderItem={(item) => (
        <List.Item className="w-full" key={item.key}>
          <Card
            bordered
            style={item.style}
            size="small"
            className="w-full rounded-xl"
            onClick={() => onClick && onClick(item)}
          >
            <Skeleton paragraph={false} loading={loading} active>
              <Card.Meta
                title={item.title}
                description={
                  typeof item.description === "string"?
                  <Input
                    readOnly
                    className="p-0"
                    variant="borderless"
                      value={item.description as any}
                  />:item.description
                }
              />
            </Skeleton>
          </Card>
        </List.Item>
      )}
    />
  );
};
