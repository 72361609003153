import { Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExchangeService } from "shared";
import { SubmitButton, MobileSelect } from "feature";

interface IFormModel {
  exchangeType: any;
  name: string;
  apiKey: string;
  apiSecret: string;
  password?: string;
  proxy: string;
}

interface ILoading {
  exchanges: boolean;
}

export const ExchangeCreate = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<IFormModel>();
  const [loading, setLoading] = useState<ILoading>({
    exchanges: true,
  });

  const [exchangeTypes, setExchangeTypes] = useState<string[]>([]);
  useEffect(() => {
    ExchangeService.getApiExchangeTypes().then((result) => {
      setExchangeTypes(result?.data!);
      setLoading({ exchanges: false });
    });
  }, []);

  const onFinish = (value: IFormModel) => {
    ExchangeService.postApiExchange({ requestBody: value }).then((result) => {
      navigate(-1);
    });
  };

  return (
    <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
      <Form.Item name="exchangeType" label="Биржа" rules={[{ required: true }]}>
        <MobileSelect
          loading={loading.exchanges}
          options={exchangeTypes.map((d) => ({
            value: d,
            label: d,
          }))}
          size="large"
        />
      </Form.Item>
      <Form.Item name="name" label="Название" rules={[{ required: true }]}>
        <Input size="large" />
      </Form.Item>
      <Form.Item name="apiKey" label="Ключ API" rules={[{ required: true }]}>
        <Input size="large" />
      </Form.Item>
      <Form.Item
        name="apiSecret"
        label="Секрет API"
        rules={[{ required: true }]}
      >
        <Input size="large" />
      </Form.Item>
      <Form.Item name="password" label="Пароль">
        <Input size="large" />
      </Form.Item>
      <Form.Item name="proxy" label="Прокси">
        <Input size="large" />
      </Form.Item>
      <Form.Item>
        <SubmitButton form={form} text="Сохранить" isMain={true} />
      </Form.Item>
    </Form>
  );
};
