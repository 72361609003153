import { Button, Flex, Skeleton, Space, Typography, theme } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { MainButton, useThemeParams } from "shared/tg-api";

export type TType = "primary" | "danger";
interface IBtnProps {
  type?: TType;
  title: string;
  disabled?: boolean;
  onClick: () => void;
}
interface EntityProps {
  loading: boolean;
  options: { label: string; value: string | number; vertical?: boolean }[];
  btn?: IBtnProps;
  title: string;
  extra?: {
    onClick: () => void;
  };
}

export const Entity = ({
  loading,
  options,
  btn,
  title,
  extra,
}: EntityProps) => {
  const { token } = theme.useToken();

  interface DescriptionItemProps {
    title: string;
    content: React.ReactNode;
    vertical: boolean;
  }

  const DescriptionItem = ({
    title,
    content,
    vertical,
  }: DescriptionItemProps) => (
    <Space size="small">
      <Flex vertical={vertical}>
        <Typography.Title level={4} style={{ margin: 10 }}>
          {title}:
        </Typography.Title>
        <Typography.Title
          level={5}
          style={{ margin: 10, color: token.colorTextSecondary }}
        >
          {content}
        </Typography.Title>
      </Flex>
    </Space>
  );

  const Btn = ({ type, title, disabled, onClick }: IBtnProps) => {
    const { token } = theme.useToken();
    const [colorScheme] = useThemeParams();

    const isDark = colorScheme === "dark";
    const disabledColor = isDark ? "#343e4b" : "#e8e8e8";
    const disabledTextColor = isDark ? "#676e78" : "#aeaeae";
    const btnTheme = {
      color: "#a73746",
      disabledColor: disabledColor,
      textColor: token.colorText,
      disabledTextColor: disabledTextColor,
    };

    return (
      <>
        <MainButton
          disabled={loading || disabled}
          theme={type === "danger" ? btnTheme : undefined}
          onClick={onClick}
        >
          {title}
        </MainButton>
      </>
    );
  };

  return (
    <Flex vertical className="h-full grid grid-cols-1 gap-1 content-between">
      <Flex vertical>
        <div className="flex justify-between">
          <Typography.Title level={3} style={{ marginTop: 0 }}>
            {loading ? <Skeleton.Input /> : title}
          </Typography.Title>
          {extra && (
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => extra.onClick && extra.onClick()}
            />
          )}
        </div>
        {options.map((i, index) => (
          <DescriptionItem
            key={index}
            title={i.label}
            content={loading ? <Skeleton.Input size="small" /> : i.value}
            vertical={i.vertical ?? false}
          />
        ))}
      </Flex>
      <div>{btn && <Btn {...btn} />}</div>
    </Flex>
  );
};
