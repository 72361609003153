import { HideTgInterface } from "feature";
import { Button, Flex, Typography } from "antd";
import { useTokenContext, useCopyToClipboard } from "shared";

export const Setting = () => {
  const token = useTokenContext();
  const [_, copy] = useCopyToClipboard();
  return (
    <Flex vertical>
      <Typography.Title level={3} style={{ margin: 0 }}>
        Настройки
      </Typography.Title>
      <Button
        className="w-full mt-7"
        type="primary"
        onClick={() => copy(token?.token)}
        size="large"
      >
        Скопировать токен
      </Button>
      <HideTgInterface />
    </Flex>
  );
};
