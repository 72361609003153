import { Entity } from "feature";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useShowConfirm, WalletService } from "shared";

interface IResponseContainer<T> {
  isSuccess: boolean;
  errorText?: string;
  data?: T;
}

interface SwapperModel {
  id: number;
  name: string;
  type: string;
  address: string;
}

export const WalletView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const showConfirm = useShowConfirm();
  const [loading, setLoading] = useState<boolean>(true);

  const [wallet, setWallet] = useState<SwapperModel>();
  useEffect(() => {
    WalletService.getApiWallet1({ id: Number.parseInt(id!) }).then(
      (result: IResponseContainer<SwapperModel>) => {
        setWallet(result?.data);
        setLoading(false);
      }
    );
  }, []);

  const onDelete = () => {
    showConfirm("Удалить кошелек?", (isOk) => {
      if (isOk) {
        WalletService.deleteApiWallet({ id: Number.parseInt(id!) }).then(
          (result) => {
            navigate(-1);
          }
        );
      }
    });
  };

  const onEdit = () => {
    navigate(`/wallets/edit/${id}`);
  };

  const options = [
    { label: "Тип кошелька", value: wallet?.type ?? "", vertical: true },
    { label: "Адрес", value: wallet?.address ?? "", vertical: true },
  ];

  return (
    <Entity
      loading={loading}
      btn={{
        type: "danger",
        title: "Удалить",
        onClick: onDelete,
      }}
      title={wallet?.name ?? ""}
      extra={{ onClick: onEdit }}
      options={options}
    />
  );
};
