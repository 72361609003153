import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, Typography } from "antd";
import { useEffect, useState } from "react";
import { SubmitButton } from "feature";
import { WalletService, useShowPopup } from "shared";

export const WalletEdit = () => {
  const [form] = Form.useForm<{ name: string }>();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    WalletService.getApiWallet1({ id: Number.parseInt(id!) }).then((result) => {
      form.setFieldsValue({
        name: result?.data?.name!,
      });
      setLoading(false);
    });
  }, []);

  const navigate = useNavigate();
  const showPopUp = useShowPopup();
  const onFinish = async (values: { name: string }) => {
    try {
      const response = await WalletService.putApiWallet({
        id: Number.parseInt(id!),
        name: values.name,
      });

      if (response && !response?.isSuccess) {
        return showPopUp({
          title: "Ошибка",
          message: response?.errorText ?? "",
        });
      }

      navigate(-1);
    } catch (error: any) {
      if (error) {
        return showPopUp({ title: "Ошибка", message: error.message });
      }
    }
  };

  return (
    <>
      <Typography.Title level={5} style={{ marginTop: 0 }}>
        Кошелёк
      </Typography.Title>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item name="name" label="Название" rules={[{ required: true }]}>
          <Input size="large" className="w-full" disabled={loading} />
        </Form.Item>
        <Form.Item>
          <SubmitButton form={form} text="Обновить" isMain={true} />
        </Form.Item>
      </Form>
    </>
  );
};
