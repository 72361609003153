import { PropsWithChildren } from "react";
import { MainButton, useThemeParams } from "shared";

export const HideTgInterface = ({ children }: PropsWithChildren) => {
  const [, themeParams] = useThemeParams();
  return (
    <>
      {children}
      <MainButton
        disabled={true}
        theme={{
          disabledColor: themeParams.secondary_bg_color,
          disabledTextColor: themeParams.secondary_bg_color,
        }}
      >
        empty
      </MainButton>
    </>
  );
};
