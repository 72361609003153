import { ReactNode, useEffect, useState } from "react";
import { HideTgInterface, Lists } from "feature";
import { EarningsService, EarningsDto } from "shared";
import { Space, Typography } from 'antd';
const { Text, Link } = Typography;

const earningTypes = new Map([
  ["SwapperPoolFee", "Доход пула"],
  ["ContractCallFee", "Вызов контракта"],
  ["ExchangeFundingFee", "Фандинг"],
  ["ExchangePnl", "Pnl хедж-позиции"],
  ["ExchangeFee", "Комиссия биржи"],
  ["AssetValueChange", "Стоимость актива"],
]);

const EarningBody = (item: EarningsDto): ReactNode => {
  return (<div>
    <div>
      <Text type={(item.value && (item.value > 0) ? "success" : "danger")}>{item.usdValue} USDT</Text>
      {item.currencyName !== "USDT" && (<Text type="secondary">  ({item.value} {item.currencyName})</Text>)}
    </div>
    {item.txUrl && (<Link href={item.txUrl} target="_blank">
      {item.tx}
    </Link>)}
    <div>{(new Date(item.createdDate!)).toLocaleString()}</div>
  </div>)
}

export const EarningList = (props:any) => {
  const [loading, setLoading] = useState(true);
  
  const [earnings, setEarnings] = useState<EarningsDto[]>([]);
  useEffect(() => {
    EarningsService.getApiEarnings(props?.positionId?{filter:[`positionId:${props?.positionId}`]}:{}).then((result) => {
      setEarnings(result?.data?.queryable ?? []);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Lists
        title={props.title}        
        loading={loading}
        data={earnings.map((d) => {
          return {
            key: d.id!,
            title: <div><Text>{earningTypes.get(d.earningsType!)} </Text> {d.description && <Text type="secondary">{d.description ? (" " + d.description) : ""}</Text>}</div>,
            description: EarningBody(d),
          };
        })}
      />
      {props.title && <HideTgInterface />}
    </>
  );
};
