import { useContext, useEffect, useId } from "react";
import { useWebApp, useSmoothButtonsTransition, systemContext } from "./core";

/**
 * The props type of {@link MainButton | `MainButton`}.
 */
export interface MainButtonProps {
  // /**
  //  * Current button text
  //  * @defaultValue Set to `CONTINUE` by default
  //  */
  // text?: string;
  children?: string | undefined;
  /**
   * The button progress state indicator.
   * @defaultValue  Set to `false` by default
   */
  progress?: boolean;
  // /**
  //  * Just an alias on the {@link MainButtonProps.disabled}
  //  * @deprecated Use {@link MainButtonProps.disabled} instead, will be removed
  //  * @ignore
  //  */
  // disable?: boolean;
  // /**
  //  * The button disable state.
  //  * @defaultValue Set to `false` y defaults
  //  */
  disabled?: boolean;
  /** The button press event handler */
  onClick?: () => void;
  // /**
  //  * Current button color.
  //  * @defaultValue Set to themeParams.button_color by default
  //  */
  // color?: string;
  // /**
  //  * Current button text color
  //  * @defaultValue Set to themeParams.button_text_color by default
  //  */
  // textColor?: string;
  theme?: {
    color?: string;
    textColor?: string;

    disabledColor?: string;
    disabledTextColor?: string;
  };
}

/**
 * Renders a {@link telegram!MainButton} component in React app as {@link react!Component}
 *
 * ```tsx
 * import { MainButton } from "@vkruglikov/react-telegram-web-app";
 *
 * <MainButton
 *     text="CLICK ME"
 *     onClick={() => console.log('Hello, I am button!')}
 * />
 * ```
 * @param props
 * @group React Components
 */

interface IMainButton {
  text?: string | undefined;
  color?: string | undefined;
  textColor?: string | undefined;
  isVisible?: boolean | undefined;
  isActive?: boolean | undefined;
}
export const MainButton = ({
  children = "",
  progress = false,
  disabled = false,
  theme,
  onClick,
}: MainButtonProps): null => {
  const system = useContext(systemContext);
  const buttonId = useId();
  const WebApp = useWebApp();
  const MainButton = WebApp?.MainButton;
  const themeParams = WebApp?.themeParams;

  // useEffect(() => {
  //   const params: IMainButton = {
  //     text: children,
  //     color:
  //       (!disabled ? theme?.color : theme?.disabledColor) ||
  //       themeParams?.button_color ||
  //       "#fff",
  //     textColor:
  //       (!disabled ? theme?.textColor : theme?.disabledTextColor) ||
  //       themeParams?.button_text_color ||
  //       "#000",
  //     isActive: !disabled,
  //   };
  //   console.log("MainButton params: ", params);

  //   MainButton?.setParams(params);
  // }, [theme, themeParams, MainButton, disabled, children]);

  useEffect(() => {
    if (!disabled) {
      MainButton?.setParams({
        color: theme?.color || themeParams?.button_color || "#fff",
        text_color:
          theme?.textColor || themeParams?.button_text_color || "#000",
      });
    } else {
      MainButton?.setParams({
        color: theme?.disabledColor || themeParams?.button_color || "#fff",
        text_color:
          theme?.disabledTextColor || themeParams?.button_text_color || "#000",
      });
    }
  }, [theme, themeParams, MainButton, disabled]);

  useEffect(() => {
    MainButton?.setText(children);
  }, [children, MainButton]);

  useEffect(() => {
    if (disabled) {
      MainButton?.disable();
    } else if (!disabled) {
      MainButton?.enable();
    }
  }, [disabled, MainButton]);

  useEffect(() => {
    if (progress) {
      MainButton?.showProgress(false);
    } else if (!progress) {
      MainButton?.hideProgress();
    }
  }, [progress, MainButton]);

  useEffect(() => {
    if (!onClick) {
      return;
    }

    MainButton?.onClick(onClick);
    return () => {
      MainButton?.offClick(onClick);
    };
  }, [onClick, MainButton]);

  useSmoothButtonsTransition({
    show: MainButton?.show,
    hide: MainButton?.hide,
    currentShowedIdRef: system.MainButton,
    id: buttonId,
  });

  return null;
};
