import { Flex, Typography } from "antd";
import { Lists } from "feature";
import React, { useEffect, useState } from "react";
import { PositionResponse, PositionService } from "shared";
import { CheckCircleOutlined, StopOutlined, WarningOutlined } from "@ant-design/icons";

const { Text } = Typography;

export const Positions = () => {
  const [loading, setLoading] = useState(true);

  const [positions, setPositions] = useState<PositionResponse[]>([]);
  useEffect(() => {
    PositionService.getApiPosition({activeOnly: true}).then((result) => {
      setPositions(result?.data ?? []);
      setLoading(false);
    });
  }, []);

  const Title = ({ pairName, swapperName, isActive, isOpened, isError, feeTier }: PositionResponse) => {
    return (
      <Flex className="flex justify-between" vertical={false}>
        <Flex className="items-baseline gap-x-2">
          <div>{pairName}</div>
          <Text code>{(feeTier ?? 0) / 10000}%</Text>
          <Text type="secondary">{swapperName}</Text>
        </Flex>
        <Flex>
          {isError && <WarningOutlined style={{ color: "#db3737" }} />}
          {isOpened && <CheckCircleOutlined style={{ color: "#52c41a" }} />}
          {isActive && !isOpened && <CheckCircleOutlined style={{ color: "#3495eb" }} />}
          {!isActive && <StopOutlined style={{ color: "#8a8787" }} />}

        </Flex>
      </Flex>
    );
  };

  const Body = ({ lowerPrice, upperPrice, profit, positionPnl }: PositionResponse) => {
    return (
      <Flex className="flex justify-between" vertical={false}>
        <Flex className="items-baseline gap-x-2">
          <div>{`${lowerPrice!} - ${upperPrice!}`}</div>
        </Flex>
        <Flex>
          {positionPnl && <Text type={(positionPnl ?? 0) >= 0 ? "success" : "danger"}>PNL~{Math.round((positionPnl ?? 0) * 1000) / 1000} USDT</Text>}
        </Flex>
        <Flex>
          <Text type={(profit ?? 0) >= 0 ? "success" : "danger"}>~{Math.round((profit ?? 0) * 1000) / 1000} USDT</Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <Lists
      loading={loading}
      data={positions.map((d) => {
        return {
          key: d.id!,
          title: <Title
            isActive={d.isActive}
            isOpened={d.isOpened}
            isError={d.isError}
            pairName={d.pairName}
            swapperName={d.swapperName}
            feeTier={d.feeTier}
          />,
          description: <Body lowerPrice={d.lowerPrice} upperPrice={d.upperPrice} profit={d.profit} positionPnl={d.positionPnl}  />,
        };
      })}
    />
  );
};
