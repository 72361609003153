import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { RoutePaths, BackButton, SettingButton } from "shared";

import { Main } from "./main";
import { Setting } from "./setting";
import { Wallet } from "./wallets";
import { Exchange } from "./exchange";
import { Position } from "./positions";
import { Earning } from "./earnings";

const RoutingPages = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [backBtnState, setBackBtnState] = useState<boolean>(false);

  useEffect(() => {
    const state = location?.key !== "default";
    setBackBtnState(state);
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="" element={<Main />} />

        <Route
          path={RoutePaths.Exchanges.Path}
          element={<Exchange.ExchangeList />}
        />
        <Route
          path={RoutePaths.Exchanges.View.Path}
          element={<Exchange.ExchangeView />}
        />
        <Route
          path={RoutePaths.Exchanges.Create.Path}
          element={<Exchange.ExchangeCreate />}
        />

        <Route
          path={RoutePaths.Positions.Path}
          element={<Position.PositionList />}
        />
        <Route
          path={RoutePaths.Positions.View.Path}
          element={<Position.PositionView />}
        />
        <Route
          path={RoutePaths.Positions.Edit.Path}
          element={<Position.PositionEdit />}
        />
        <Route
          path={RoutePaths.Positions.Create.First.Path}
          element={<Position.PositionCreatePartFirst />}
        />
        <Route
          path={RoutePaths.Positions.Create.Second.Path}
          element={<Position.PositionCreatePartSecond />}
        />

        <Route path={RoutePaths.Wallets.Path} element={<Wallet.WalletList />} />
        <Route
          path={RoutePaths.Wallets.View.Path}
          element={<Wallet.WalletView />}
        />
        <Route
          path={RoutePaths.Wallets.Edit.Path}
          element={<Wallet.WalletEdit />}
        />
        <Route
          path={RoutePaths.Wallets.Create.Path}
          element={<Wallet.WalletCreate />}
        />

        <Route
          path={RoutePaths.Earnings.Path}
          element={<Earning.EarningList title={"Заработок"} />}
        />

        <Route path={RoutePaths.Setting.Path} element={<Setting />} />
      </Routes>
      {backBtnState && <BackButton onClick={() => navigate(-1)} />}
      <SettingButton onClick={() => navigate(RoutePaths.Setting.Path)} />
    </>
  );
};

export default RoutingPages;
