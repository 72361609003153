import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HideTgInterface, Lists } from "feature";
import { RoutePaths, WalletService } from "shared";

interface WalletModel {
  id: number;
  type: string;
  name: string;
  address: string;
}

export const WalletList = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [wallets, setWallets] = useState<WalletModel[]>([]);
  useEffect(() => {
    WalletService.getApiWallet().then((result) => {
      setWallets(result?.data ?? []);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Lists
        title="Кошельки"
        loading={loading}
        data={wallets.map((d) => {
          return {
            key: d.id!,
            title: d.name!,
            description: d.address!,
          };
        })}
        onClick={(item) => navigate(`/wallets/${item.key}`)}
        onAddClick={() => navigate(RoutePaths.Wallets.Create.Path)}
      />
      <HideTgInterface />
    </>
  );
};
