import React, { useEffect } from "react";
import { provider, useExpand } from "shared";
import RoutingPages from "pages";

const App = () => {
  const [, expand] = useExpand();
  useEffect(() => {
    expand();
  }, []);

  return (
    <provider.WebAppProvider options={{ smoothButtonsTransition: true }}>
      <provider.ThemeProvider>
        <provider.TokenProvider>
          <RoutingPages />
        </provider.TokenProvider>
      </provider.ThemeProvider>
    </provider.WebAppProvider>
  );
};

export default App;
