import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HideTgInterface, Lists } from "feature";
import { ExchangeService, LightExchangeResponse, RoutePaths } from "shared";

export const ExchangeList = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [exchanges, setExchanges] = useState<LightExchangeResponse[]>([]);
  useEffect(() => {
    ExchangeService.getApiExchange().then((result) => {
      setExchanges((result?.data as any) ?? []);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Lists
        title="Доступы"
        loading={loading}
        data={exchanges.map((d) => {
          return {
            key: d.id!,
            title: d.name!,
            description: d.type!,
          };
        })}
        onClick={(item) => navigate(`/exchanges/${item.key}`)}
        onAddClick={() => navigate(RoutePaths.Exchanges.Create.Path)}
      />
      <HideTgInterface />
    </>
  );
};
