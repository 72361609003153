import { Button, Form, FormInstance, theme } from "antd";
import { useEffect, useState } from "react";
import { MainButton, useThemeParams } from "shared";

export interface SubmitButtonProps {
  text: string;
  form: FormInstance;
  isMain?: boolean;
}
export const SubmitButton = ({ form, text, isMain }: SubmitButtonProps) => {
  const [submittable, setSubmittable] = useState(false);
  const { token } = theme.useToken();
  const [colorScheme] = useThemeParams();
  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  const isDark = colorScheme === "dark";
  const disabledColor = isDark ? "#343e4b" : "#e8e8e8";
  const disabledTextColor = isDark ? "#676e78" : "#aeaeae";

  return isMain ? (
    <MainButton
      disabled={!submittable}
      theme={{
        color: token.Button?.primaryColor,
        disabledColor: disabledColor,
        textColor: token.colorText,
        disabledTextColor: disabledTextColor,
      }}
      onClick={() => form.submit()}
    >
      {text}
    </MainButton>
  ) : (
    <Button
      type="primary"
      htmlType="submit"
      className="w-full mt-4"
      disabled={!submittable}
      size="large"
    >
      {text}
    </Button>
  );
};
