import { Form, Input } from "antd";
import { UserWalletDto, WalletService } from "shared";
import { useNavigate } from "react-router-dom";
import { SubmitButton } from "feature";

const { TextArea } = Input;
interface FormModel {
  name: string;
  privatekey: string;
}

export const WalletCreate = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<FormModel>();

  const onFinish = (values: FormModel) => {
    const request: UserWalletDto = {
      name: values.name,
      privateKey: values.privatekey,
    };
    WalletService.postApiWallet({ requestBody: request }).then((result) => {
      navigate(-1);
    });
  };

  return (
    <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
      <Form.Item name="name" label="Название" rules={[{ required: true }]}>
        <Input size="large" className="w-full" />
      </Form.Item>
      <Form.Item
        name="privatekey"
        label="Приватный ключ"
        rules={[{ required: true }]}
      >
        <TextArea size="large" autoSize={{ minRows: 3, maxRows: 3 }} />
      </Form.Item>
      <Form.Item>
        <SubmitButton form={form} text="Сохранить" isMain={true} />
      </Form.Item>
    </Form>
  );
};
