/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExchangeRequest } from '../models/ExchangeRequest';
import type { ExchangeResponseResponseData } from '../models/ExchangeResponseResponseData';
import type { LightExchangeResponseIEnumerableResponseData } from '../models/LightExchangeResponseIEnumerableResponseData';
import type { StringIEnumerableResponseData } from '../models/StringIEnumerableResponseData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ExchangeService {
    /**
     * @returns LightExchangeResponseIEnumerableResponseData Success
     * @throws ApiError
     */
    public static getApiExchange(): CancelablePromise<LightExchangeResponseIEnumerableResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/exchange',
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postApiExchange({
        requestBody,
    }: {
        requestBody?: ExchangeRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/exchange',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static putApiExchange({
        requestBody,
    }: {
        requestBody?: ExchangeRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/exchange',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns ExchangeResponseResponseData Success
     * @throws ApiError
     */
    public static getApiExchange1({
        exchangeId,
    }: {
        exchangeId: number,
    }): CancelablePromise<ExchangeResponseResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/exchange/{exchangeId}',
            path: {
                'exchangeId': exchangeId,
            },
        });
    }
    /**
     * @returns StringIEnumerableResponseData Success
     * @throws ApiError
     */
    public static getApiExchangeTypes(): CancelablePromise<StringIEnumerableResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/exchange/types',
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiExchange({
        id,
    }: {
        id: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/exchange/{id}',
            path: {
                'id': id,
            },
        });
    }
}
