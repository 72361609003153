import { CheckCircleOutlined, CloseCircleOutlined, FireOutlined, MinusCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Flex, Space, Tag, Card, Typography, Tabs } from "antd";
import { ListDataModel, Lists, Screen } from "feature";
import { EarningList } from "pages/earnings/ui";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useShowConfirm, PositionInfo, PositionService, OrderDto, useShowPopup } from "shared";
import { DashboardWidget } from "widgets/dashboard";
const { Text, Title } = Typography;

export const PositionView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const showConfirm = useShowConfirm();
  const showPopUp = useShowPopup();

  const [loading, setLoading] = useState<boolean>(true);
  const [position, setPosition] = useState<PositionInfo>();
  useEffect(() => {
    PositionService.getApiPosition1({ positionId: Number.parseInt(id!) }).then(
      (result) => {
        setPosition(result?.data);
        setLoading(false);
      }
    );
  }, [id]);

  const onDelete = () => {
    if (position?.isActive === false) return;
    showConfirm("Закрыть позицию?", async (isOk) => {
      if (isOk) {
        setLoading(true)
        try {
          const response = await PositionService.deleteApiPosition({
            positionId: Number.parseInt(id!),
          })
          debugger
          if (response && !response?.isSuccess) {
            return showPopUp({
              title: "Ошибка",
              message: response?.errorText ?? "",
            });
          }
          else {
            navigate(-1);
          }
        }
        catch (error: any) {
          if (error) {
            return showPopUp({ title: "Ошибка", message: error.message });
          }
        }
        finally {
          setLoading(false)
        }

      }
    });
  };
  const onOpen = () => {
    if (position?.isActive === true) return;
    showConfirm("Открыть позицию?", async (isOk) => {
      if (isOk) {

        setLoading(true)
        try {
          const response = await PositionService.postApiPositionOpen({
            positionId: Number.parseInt(id!),
          });
          if (response && !response?.isSuccess) {
            return showPopUp({
              title: "Ошибка",
              message: response?.errorText ?? "",
            });
          }
          else {
            navigate(-1);
          }
        }
        catch (error: any) {
          if (error) {
            return showPopUp({ title: "Ошибка", message: error.message });
          }
        }
        finally {
          setLoading(false)
        }
      }
    });
  };

  const dropError = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    showConfirm("Сбросить ошибку?", (isOk) => {
      if (isOk) {
        PositionService.deleteApiPositionError({
          positionId: Number.parseInt(id!),
        }).then((result) => {
          navigate(0);
        });
      }
    });
  };

  const onEdit = () => {
    navigate(`/positions/edit/${id}`, { state: position });
  };

  const Status = () => {
    return <Space size={[0, 8]} wrap>
      {position?.isOpened ? (<Tag icon={<CheckCircleOutlined />} color="success">В работе</Tag>) : (
        position?.isActive ? <Tag icon={<SyncOutlined spin />} color="processing">В ожидании</Tag> : <Tag icon={<MinusCircleOutlined />} color="default">Остановлен</Tag>
      )}
      {position?.isError && <Tag icon={<CloseCircleOutlined />} color="error" closeIcon onClose={dropError}>Ошибка</Tag>}
    </Space>;
  }

  const Order = (d: OrderDto): ListDataModel => {
    return {
      key: d.id!,
      title: <div>
        <Text type={d.positionSide === "Long" ? "success" : "danger"} >
          {`${(d.positionSide === "Long" && d.orderSide === "Buy") || (d.positionSide === "Short" && d.orderSide === "Sell") ? "Open" : "Close"} ${d.positionSide}`}
        </Text> {d.isFilled && <FireOutlined twoToneColor={"orange"} />}</div>,
      description: <Flex gap={5} justify="space-between">
        <Text strong>Price: {d.price}</Text>
        <Text type="secondary" italic>{(new Date(d.createdDate!)).toLocaleString()}</Text>
      </Flex>,
      style: { borderColor: d.isPlaced ? (d.isFilled ? "#87d068" : "#2db7f5") : "gray" }
    };
  }

  const tabs = [
    {
      key: '1',
      label: 'Доходы/Расходы',
      children: <EarningList positionId={position?.id} />,
    },
    {
      key: '2',
      label: 'Ордера',
      children: <Lists loading={false} data={position?.orders?.filter(o => !o.closedDate).map((d) => Order(d)) ?? []} />,
    },
    {
      key: '3',
      label: 'История',
      children: <Lists loading={false} data={position?.orders?.map((d) => Order(d)) ?? []} />,
    },
  ]

  const positionShortInfo = (position: PositionInfo) => {
    return (
      <Flex vertical style={{ height: "100%" }}>
        <Card
          bordered
          size="small"
          className="w-full rounded-xl">
          <Flex gap={3} vertical>
            <Flex gap={5} >
              <Text >Статус:</Text><Status />
            </Flex>
            <Flex gap={5} justify="space-between" align="center">
              <Text >Объем: {position.amount} USD</Text>
              <Text code >{`Диапазон: ${position.lowerPrice}-${position.upperPrice}`}</Text>
            </Flex>
            <Flex style={{ height: "80px" }}>
              {position?.id && <DashboardWidget positionId={position?.id} height="100%" />}
            </Flex>
          </Flex>

        </Card>
        <Tabs defaultActiveKey="1" centered items={tabs} />
      </Flex>)
  }

  const extra =
    (position?.isActive === undefined || position?.isActive) && !position?.isError
      ? undefined
      : { onClick: onEdit };

  return (
    <Screen loading={loading}
      btn={position?.isActive === false ?
        {
          type: "primary",
          title: "Открыть позицию",
          disabled: position?.isActive !== false,
          onClick: onOpen,
        } : {
          type: "danger",
          title: "Закрыть позицию",
          disabled: position?.isActive !== true,
          onClick: onDelete,
        }}
      title={`${position?.token0?.symbol}-${position?.token1?.symbol} ${position?.swapper?.name} (${(position?.feeTier ?? 0) / 10000}%)`}
      extra={extra} >
      <>
        {position && positionShortInfo(position!)}

      </>

    </Screen>
    //  <Entity
    //     loading={loading}
    //     btn={{
    //       type: "danger",
    //       title: "Закрыть позицию",
    //       disabled: position?.isActive !== true,
    //       onClick: onDelete,
    //     }}
    //     title={`${position?.token0?.symbol}-${position?.token1?.symbol}`}
    //     options={options}
    //     extra={extra}
    //   /> 
  );
};
