import { useContext } from "react";
import { webAppContext } from "./context";
import { WebApp } from "./twa-types";

/**
 * @private
 * @ignore
 */
export const useWebApp = () => {
  const context = useContext<WebApp | null>(webAppContext);

  return context;
};
