import { Entity } from "feature";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ExchangeResponse, ExchangeService, useShowConfirm } from "shared";

export const ExchangeView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const showConfirm = useShowConfirm();

  const [loading, setLoading] = useState<boolean>(true);
  const [sccess, setAccess] = useState<ExchangeResponse>();
  useEffect(() => {
    ExchangeService.getApiExchange1({
      exchangeId: Number.parseInt(id!),
    }).then((result) => {
      setAccess(result?.data);
      setLoading(false);
    });
  }, []);

  const onDelete = () => {
    showConfirm("Удалить доступ?", (isOk) => {
      if (isOk) {
        ExchangeService.deleteApiExchange({ id: Number.parseInt(id!) }).then(
          (result) => {
            navigate(-1);
          }
        );
      }
    });
  };

  const options = [
    { label: "Тип обменника", value: sccess?.type ?? "" },
    { label: "Ключ API", value: sccess?.apiKeyShort ?? "" },
    { label: "Прокси", value: sccess?.proxy ?? "" },
  ];

  return (
    <Entity
      loading={loading}
      btn={{
        type: "danger",
        title: "Удалить",
        onClick: onDelete,
      }}
      title={sccess?.name ?? ""}
      options={options}
    />
  );
};
