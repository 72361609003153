import { useCallback } from "react";
import { useWebApp } from "./core";

/**
 * This function provides Promise, and resolve the field id of the pressed button will be passed.
 * @return Button id as string, it was described by {@link ShowPopupButton}
 * @throws
 */
export type ShowConfirmFunction = (
  message: string,
  callback?: (isOk: boolean) => void
) => Promise<(isOk: boolean) => void>;

/**
 * The hook provided showPopup function of the type {@link ShowPopupFunction}.
 * The function that shows a native popup described by the params argument of the type {@link ShowPopupParams}.
 *
 * ```tsx
 * import { useShowPopup } from "@vkruglikov/react-telegram-web-app";
 *
 * const showPopup = useShowPopup();
 *
 * showPopup({ message: 'Hello world' }).then((buttonId) => console.log(buttonId));
 * ```
 *
 * @group Hooks
 */
export const useShowConfirm: () => ShowConfirmFunction = () => {
  const WebApp = useWebApp();

  return useCallback((...args) => WebApp?.showConfirm?.(...args), [WebApp]);
};
