import { Carousel, Spin } from "antd";
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
} from "recharts";
import { Dashboard, EarningsService } from "shared/api";

export type DashboardWidgetProps = {
  height?: string | number
  positionId?: number;
}

export const DashboardWidget = (props: DashboardWidgetProps) => {

  const [loading, setLoading] = useState(true);

  const [dashbordData, setDashboardData] = useState<Dashboard>();
  useEffect(() => {
    EarningsService.getApiEarningsDashboard({ positionId: props.positionId }).then((result) => {
      setDashboardData(result.data);
      setLoading(false);

    });
  }, []);


  return (
    <ResponsiveContainer width="100%" height="20%" {...props}>
      {loading ?
        <Spin spinning={loading} /> :
        <Carousel>
          <BarChart
          width={730}
          height={80}

          data={dashbordData?.weekProfitItems as any[]}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          
          <ReferenceLine y={0} stroke="#000" />
          <XAxis dy={30} dataKey={(item)=>new Date(item.date).getDate()} />
          <Bar dataKey="value" label={{ position: 'bottom' }}  >
            {dashbordData?.weekProfitItems?.map((entry, index) => (
              <Cell fill={(entry.value ?? 0) < 0 ? '#8884d8' : '#82ca9d'} key={`cell-${index}`} />
            ))}
          </Bar>
          
        </BarChart>
        <BarChart
          width={730}
          height={80}

          data={dashbordData?.monthProfitItems as any[]}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="value" >
            {dashbordData?.monthProfitItems?.map((entry, index) => (
              <Cell fill={(entry.value ?? 0) < 0 ? '#8884d8' : '#82ca9d'} key={`cell-${index}`} />
            ))}
          </Bar>
          <XAxis dy={30}  dataKey={(item)=>new Date(item.date).getDate()} />
        </BarChart>
        <BarChart
          width={730}
          height={80}

          data={dashbordData?.yearProfitItems as any[]}
          margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
        >
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="value" label={{ position: 'bottom' }}  >
            {dashbordData?.yearProfitItems?.map((entry, index) => (
              <Cell fill={(entry.value ?? 0) < 0 ? '#8884d8' : '#82ca9d'} key={`cell-${index}`} />
            ))}
          </Bar>
          <XAxis dy={30}  dataKey="date" />
        </BarChart>
        </Carousel>
      }
    </ResponsiveContainer>
  );
};
