import React, { PropsWithChildren } from "react";
import ReactDOM from "react-dom";

export class BodyEnd extends React.Component {
  divElement: HTMLDivElement;
  props: PropsWithChildren;
  constructor(props: PropsWithChildren) {
    super(props);
    this.props = props;
    this.divElement = document.createElement("div");
  }

  componentDidMount() {
    document.body.appendChild(this.divElement);
  }

  componentWillUnmount() {
    document.body.removeChild(this.divElement);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.divElement);
  }
}
