/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NetDtoIEnumerableResponseData } from '../models/NetDtoIEnumerableResponseData';
import type { PoolAmountResponseData } from '../models/PoolAmountResponseData';
import type { SwapperPoolItemIEnumerableResponseData } from '../models/SwapperPoolItemIEnumerableResponseData';
import type { SwapperResponseIEnumerableResponseData } from '../models/SwapperResponseIEnumerableResponseData';
import type { TokenDtoIEnumerableResponseData } from '../models/TokenDtoIEnumerableResponseData';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DictionaryService {
    /**
     * @returns NetDtoIEnumerableResponseData Success
     * @throws ApiError
     */
    public static getApiDictionaryNetworks({
        swapperId,
    }: {
        swapperId?: number,
    }): CancelablePromise<NetDtoIEnumerableResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dictionary/networks',
            query: {
                'swapperId': swapperId,
            },
        });
    }
    /**
     * @returns SwapperResponseIEnumerableResponseData Success
     * @throws ApiError
     */
    public static getApiDictionarySwappers({
        netId,
    }: {
        netId?: number,
    }): CancelablePromise<SwapperResponseIEnumerableResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dictionary/swappers',
            query: {
                'netId': netId,
            },
        });
    }
    /**
     * @returns TokenDtoIEnumerableResponseData Success
     * @throws ApiError
     */
    public static getApiDictionaryTokens({
        netId,
    }: {
        netId: number,
    }): CancelablePromise<TokenDtoIEnumerableResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dictionary/tokens',
            query: {
                'netId': netId,
            },
        });
    }
    /**
     * @returns SwapperPoolItemIEnumerableResponseData Success
     * @throws ApiError
     */
    public static getApiDictionaryPools({
        netId,
        swapperId,
        token0Id,
        token1Id,
    }: {
        netId: number,
        swapperId: number,
        token0Id: number,
        token1Id: number,
    }): CancelablePromise<SwapperPoolItemIEnumerableResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dictionary/pools',
            query: {
                'netId': netId,
                'swapperId': swapperId,
                'token0Id': token0Id,
                'token1Id': token1Id,
            },
        });
    }
    /**
     * @returns PoolAmountResponseData Success
     * @throws ApiError
     */
    public static getApiDictionaryPoolAmount({
        netId,
        swapperId,
        token0Id,
        token1Id,
        feeTier,
        lowerPrice,
        upperPrice,
        token0Amount,
        token1Amount,
    }: {
        netId: number,
        swapperId: number,
        token0Id: number,
        token1Id: number,
        feeTier: number,
        lowerPrice: number,
        upperPrice: number,
        token0Amount?: number,
        token1Amount?: number,
    }): CancelablePromise<PoolAmountResponseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dictionary/pool/amount',
            query: {
                'netId': netId,
                'swapperId': swapperId,
                'token0Id': token0Id,
                'token1Id': token1Id,
                'feeTier': feeTier,
                'lowerPrice': lowerPrice,
                'upperPrice': upperPrice,
                'token0Amount': token0Amount,
                'token1Amount': token1Amount,
            },
        });
    }
}
