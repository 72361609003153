import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { Button, Flex, Input, List, theme } from "antd";
import { useEffect, useState } from "react";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import "./index.scss";
import { BodyEnd } from "./bodyEnd";
import { useThemeParams } from "shared/tg-api";

interface MobileSelectProps<
  ValueType = any,
  OptionType extends BaseOptionType = DefaultOptionType
> {
  options?: OptionType[];
  value?: ValueType | null;
  onChange?: (value: ValueType, option: OptionType | OptionType[]) => void;
  size?: "small" | "middle" | "large" | undefined;
  disabled?: boolean;
  loading?: boolean;
}
export const MobileSelect = ({
  options,
  value,
  onChange,
  size,
  disabled = false,
  loading = false,
}: MobileSelectProps) => {
  const [state, setState] = useState<any | undefined>();
  const [open, setOpen] = useState(false);
  const { token } = theme.useToken();

  useEffect(() => {
    const _value = options?.find((x) => x.value === value)?.label;
    setState(_value ?? undefined);
  }, [value]);

  const onSelect = (value: BaseOptionType) => {
    onClose();
    onChange && onChange(value.value, value as any);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="relative">
      <Button
        className="mobile-select-btn"
        size={size}
        onClick={() => showDrawer()}
        disabled={disabled || loading}
      >
        <Flex justify="space-between" className="h-full">
          <Input
            readOnly
            value={state}
            className="p-0"
            variant="borderless"
            onClick={() => showDrawer()}
          />
          <div className="h-full flex items-center ">
            {!loading && (
              <DownOutlined
                style={{ fontSize: "12px", color: token.colorTextPlaceholder }}
              />
            )}
            {loading && <LoadingOutlined style={{ fontSize: 12 }} spin />}
          </div>
        </Flex>
      </Button>
      {open && (
        <BodyEnd>
          <div className="panel-i" onClick={() => onClose()}>
            <div
              onClick={() => onClose()}
              className="mask"
              style={{ backgroundColor: token.colorBgMask }}
            >
              <div className="content-wrapper">
                <div
                  className="content"
                  style={{ backgroundColor: token.colorBgContainer }}
                >
                  <div className="content-body">
                    <List
                      className="h-full"
                      pagination={false}
                      dataSource={options}
                      renderItem={(item) => (
                        <List.Item
                          className="w-full"
                          key={item.value}
                          onClick={() => {
                            onSelect(item);
                          }}
                        >
                          <Input
                            readOnly
                            className="p-0"
                            variant="borderless"
                            value={item.label as any}
                          />
                        </List.Item>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BodyEnd>
      )}
    </div>
  );
};
