export class RoutePaths {
  static Exchanges = {
    Path: "/exchanges",
    View: {
      Path: "/exchanges/:id",
    },
    Create: {
      Path: "/exchanges/create",
    },
  };
  static Positions = {
    Path: "/positions",
    View: {
      Path: "/positions/:id",
    },
    Edit: {
      Path: "/positions/edit/:id",
    },
    Create: {
      First: { Path: "/positions/create/first-part" },
      Second: { Path: "/positions/create/second-part" },
    },
  };
  static Wallets = {
    Path: "/wallets",
    View: {
      Path: "/wallets/:id",
    },
    Edit: {
      Path: "/wallets/edit/:id",
    },
    Create: {
      Path: "/wallets/create",
    },
  };
  static Earnings = {
    Path: "/earnings",
  };
  static Setting = {
    Path: "/setting",
  };
}
