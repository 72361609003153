import { useContext, useEffect, useId } from "react";
import { useWebApp, useSmoothButtonsTransition, systemContext } from "./core";

/**
 * The props type of {@link SettingsButton | `SettingsButton`}.
 */
export interface SettingButtonProps {
  /** The back button press event handler */
  onClick?: () => void;
}

/**
 * Renders a {@link telegram!SettingsButton} component in React app as {@link react!Component}
 *
 * ```tsx
 * import { SettingsButton } from "@vkruglikov/react-telegram-web-app";
 *
 * <SettingsButton
 *     onClick={() => console.log('Hello, I am setting button!')}
 * />
 * ```
 * @param props
 * @group React Components
 */
export const SettingButton = ({ onClick }: SettingButtonProps): null => {
  const system = useContext(systemContext);
  const buttonId = useId();
  const WebApp = useWebApp();
  const SettingsButton = WebApp?.SettingsButton;

  useEffect(() => {
    if (!onClick || !SettingsButton) {
      return;
    }

    SettingsButton.onClick(onClick);
    return () => {
      SettingsButton.offClick(onClick);
    };
  }, [onClick, SettingsButton]);

  useSmoothButtonsTransition({
    show: SettingsButton?.show,
    hide: SettingsButton?.hide,
    currentShowedIdRef: system.SettingsButton,
    id: buttonId,
  });

  return null;
};
